type EnvVar = keyof Window['_env_'];

/**
 * Класс для получения переменных окружения
 */
export class RuntimeEnv {
	private static getSource() {
		return window._env_ ? window._env_ : process.env;
	}

	/**
	 * Используем этот метод для получения всех переменных окружения.
	 * Не обязательных переменных окружения - в приложении нет.
	 * Кладём приложение если оно неправильно настроено.
	 * @param name - имя (обязательной) переменной окружения
	 */
	private static assertEnv(name: EnvVar) {
		const value = RuntimeEnv.getSource()[name];
		if (!value) {
			throw new Error(`Переменная окружения "${name}" не установлена!`);
		}
		return value;
	}

	public static getEnv(name: EnvVar) {
		return RuntimeEnv.assertEnv(name);
	}
}
