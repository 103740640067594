import typo from '../UI/Typography/Typography.module.css';

type PlotWrapperProps = {
  children: React.ReactNode,
  title: string,
  className?: string
}

export const PlotWrapper = ({ children, title, className }: PlotWrapperProps) => {
  return <div className={`${className} border border-divider rounded-2 p-4 shadow`}>
    <h2 className={typo.h5 + ' text-character-title'}>{title}</h2>
    {children}
  </div>
}