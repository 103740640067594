import { BidirectionalBar } from "@ant-design/charts";
import { Spin } from "antd";

type DataProps = Array<Record<string, number>>;

type PlotBidirectionalBarProps = {
  colorPalette?: string[];
  data: DataProps;
  isFetching?: boolean;
  axes: string[];
};

export const PlotBidirectionalBar: React.FC<PlotBidirectionalBarProps> = ({
  data,
  colorPalette,
  axes,
  isFetching,
}) => {
  const config = {
    data,
    xField: "unit",
    yField: axes,
    axis: {
      x: { position: "left" as const, label: "true" },
    },
    legend: {
      color: {
        layout: {
          justifyContent: "center",
          alignItems: "flex-end",
          itemMarkerFill: "8000FF",
        },
      },
    },
    tooltip: {
      title: "",
      items: [
        {
          channel: "y",
          color: colorPalette ? colorPalette[0] : "",
          field: axes[0],
        },
        {
          channel: "y1",
          field: axes[1],
          color: colorPalette ? colorPalette[1] : "",
        },
      ],
    },
    layout: "horizontal" as const,
    style: {
      fill: (data: {
        currentMonth: number;
        day: string;
        groupKey: string;
        lastMonth: number;
      }) => {
        if (colorPalette) {
          if (data.groupKey === axes[0]) return colorPalette[0];
          return colorPalette[1];
        } else {
          return "#FFC53D";
        }
      },
      opacity: 0.85,
    },
  };

  return isFetching ? (
    <div className={"flex items-center justify-center h-[480px]"}>
      <Spin />
    </div>
  ) : (
    <BidirectionalBar {...config} />
  );
};
