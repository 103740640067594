import {
  Navigate,
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import { ROUTER } from "../constants/routes";
import { ListPage } from "../pages/List/ListPage";
import { GraphicPage } from "../pages/Graphics/GraphicsPage";
import { NotFoundPage } from "./NotFound/NotFound";

export const RouterPage = () => {
  return (
    <Router>
      <Routes>
        {/* <Route path={ROUTER.LOGIN.PATH} element={<LoginPage />} /> */}
        <Route path={ROUTER.LIST.PATH} element={<ListPage />} />
        <Route path={ROUTER.GRAPHICS.PATH} element={<GraphicPage />} />
        <Route path="/" element={<Navigate to={ROUTER.LIST.PATH} />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
};
