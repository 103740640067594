import { PlotWrapper } from "../../components/Plots/PlotWrapper";
import { DashboardLayout } from "../../components/DashboardLayout/DashboardLayout";
import { Plot } from "../../components/Plots/Plot";
import { PlotArea } from "../../components/Plots/PlotArea";
import { PlotBidirectionalBar } from "../../components/Plots/PlotBidirectionalBar";
import { filterSelector } from "src/store/slices/filterSlice";
import { useStoreSelector } from "src/store/store";
import {
  useGetGraphRejectsStatisticQuery,
  useGetGraphTimeOfVisitStatisticQuery,
  useGetGraphVisitorsStatisticDataQuery,
} from "src/store/api";
import { getWordEnding } from "src/utils/getWordEnding";

const options: Record<string, string> = {
  day: "день",
  week: "неделя",
  month: "месяц",
  year: "год",
};

export type DateType = {
  unit: number;
  value: number;
  type: string;
};

export const GraphicPage = () => {
  const { period } = useStoreSelector(filterSelector);

  const { currentData: visitorsData, isFetching: isFetchingVisitorsData } =
    useGetGraphVisitorsStatisticDataQuery(period);
  const { currentData: timeData, isFetching: isFetchingTimeData } =
    useGetGraphTimeOfVisitStatisticQuery(period);
  const { currentData: rejectData, isFetching: isFetchingRejectData } =
    useGetGraphRejectsStatisticQuery(period);

  const prefix = (word: string) => {
    return options[period] === "неделя" ? getWordEnding(word, "female") : word;
  };

  const axes = {
    now: `${prefix("Текущий")} ${options[period]}`,
    prev: `${prefix("Прошлый")} ${options[period]}`,
  };

  const dataRejectGraph = rejectData
    ? rejectData.statistic.units.map((item) => {
        const unitValue =
          "day" in item
            ? item.day
            : "month" in item
            ? item.month + 1
            : item.hour;

        return {
          unit: unitValue,
          [axes.prev]: item.rejects.previous,
          [axes.now]: item.rejects.now,
        };
      })
    : [];

  const dataVisitorsGraph = (visitorsData?.statistic.units || []).flatMap(
    (item) => {
      const unitValue =
        "day" in item ? item.day : "month" in item ? item.month : item.hour;

      return [
        {
          unit: unitValue,
          value: item.visitors.now,
          type: axes.now,
        },
        {
          unit: unitValue,
          value: item.visitors.previous,
          type: axes.prev,
        },
      ];
    }
  );

  const dataTimeGraph = (timeData?.statistic.units || []).flatMap((item) => {
    const unitValue =
      "day" in item ? item.day : "month" in item ? item.month : item.hour;

    return [
      {
        unit: unitValue,
        value: item.timeOfVisit.now / 60,
        type: axes.now,
      },
      {
        unit: unitValue,
        value: item.timeOfVisit.previous / 60,
        type: axes.prev,
      },
    ];
  });

  return (
    <DashboardLayout>
      <div className="flex flex-wrap gap-x-6 gap-y-4">
        <PlotWrapper title="Посетители" className={"w-full"}>
          <Plot data={dataVisitorsGraph} isFetching={isFetchingVisitorsData} />
        </PlotWrapper>

        <PlotWrapper
          title="Количество отказов"
          className={"w-[calc(50%-12px)]"}
        >
          <PlotBidirectionalBar
            colorPalette={["#FFC53D", "#2FC25B"]}
            data={dataRejectGraph}
            isFetching={isFetchingRejectData}
            axes={[
              `${prefix("Прошлый")} ${options[period]}`,
              `${prefix("Текущий")} ${options[period]}`,
            ]}
          />
        </PlotWrapper>

        <PlotWrapper
          title="Время нахождения в ресторане"
          className={"w-[calc(50%-12px)]"}
        >
          <PlotArea
            colorPalette={["#1890FF", "#2FC25B"]}
            data={dataTimeGraph}
            isFetching={isFetchingTimeData}
          />
        </PlotWrapper>
      </div>
    </DashboardLayout>
  );
};
