export const comparisonData = (prev: number, now: number) => {

  const diff = 100 - (now * 100 / prev);

  if (!prev) {
    return
  }

  if (diff < 0) {
    return { value: Math.abs(diff).toFixed(1), status: 'increase' }
  } else if (diff > 0) {
    return { value: Math.abs(diff).toFixed(1), status: 'decrease' }
  } else {
    return
  }
}