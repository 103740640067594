export const ROUTER = {
  ROOT: {
    PATH: '/',
    TITLE: 'Главная',
  },
  LOGIN: {
    PATH: '/login',
    TITLE: 'Авторизация',
  },
  LIST: {
    PATH: '/list',
    TITLE: 'Список',
  },
  GRAPHICS: {
    PATH: '/graphics',
    TITLE: 'График',
  },
} as const;
