import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StoreRootState } from "../store";

const initialState = {
  period: 'month'
};

export const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setFilter(state, action: PayloadAction<string>) {
      state.period = action.payload;
    },
  },
});

export const filterSelector = (state: StoreRootState) => {
  return state.filter;
};