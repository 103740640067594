import { Layout } from "antd";
import { Content, Header } from 'antd/es/layout/layout';
import { Navigation } from '../Navigation/Navigation';

import { ConfigProvider } from "antd";
import ruRU from 'antd/locale/ru_RU';

import typo from '../UI/Typography/Typography.module.css'

export const DashboardLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <ConfigProvider locale={ruRU}>
      <Layout className={'min-h-[100vh] bg-[white]'}>
        <Header className={'text-[white] bg-volcano-5 py-6 flex items-center px-6'}>
          <h1 className={typo.h4}>Посещение ресторана</h1>
        </Header>
        <Content className={'p-6 pt-0'}>
          <Navigation />
          {children}
        </Content>
      </Layout>
    </ConfigProvider>
  )
}