import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { Statistic } from "antd";
import { comparisonData } from "../../utils/statisticComparison";

type TData = {
  now: number;
  previous: number;
};

type StatisticWrapperProps = {
  data: TData;
  title?: string;
  suffix?: string;
  isFetching?: boolean;
};

export const StatisticWrapper = ({
  data,
  title,
  suffix,
  isFetching,
}: StatisticWrapperProps) => {
  const countStatistic = comparisonData(data.previous, data.now);

  return (
    <div className="flex-grow">
      <Statistic
        loading={isFetching}
        title={title}
        value={data.now}
        suffix={suffix}
      />
      {countStatistic && (
        <p className="text-12 text-character-title">
          По сравнению с прошлым месяцем
          <span
            className={`${
              countStatistic.status === "increase"
                ? "text-polarGreen"
                : "text-dustRed"
            } ml-1`}
          >
            {countStatistic.status === "increase" ? (
              <CaretUpOutlined />
            ) : (
              <CaretDownOutlined />
            )}
            {countStatistic.value}%
          </span>
        </p>
      )}
    </div>
  );
};
