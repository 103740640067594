import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StoreRootState } from '../store';
import { api } from '../api';

type CurrentUser = {
	authString: string;
	userName: string;
};
type AuthState = {
	user: Nullable<CurrentUser>;
};

const reHydrateStore = (): Nullable<CurrentUser> => {
	const user = localStorage.getItem('creds');
	if (user !== null) {
		const { username, password } = JSON.parse(user);
		return {
			authString: btoa(`${username}:${password}`),
			userName: username,
		};
	}
	return null;
};

const initialState = (): AuthState => {
	return { user: reHydrateStore() };
};

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setUser: (state, { payload }: PayloadAction<{ username: string; password: string }>) => {
			state.user = {
				authString: btoa(`${payload.username}:${payload.password}`),
				userName: payload.username,
			};
		},
		clearUser: (state) => {
			state.user = null;
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(api.endpoints.login.matchRejected, (state) => {
			state.user = null;
		});
	},
});

export const authSelector = (state: StoreRootState) => {
	return state.auth;
};
