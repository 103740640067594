import { Pagination, Spin, Table } from "antd";
import { useState } from "react";
import { useGetListStatisticDataQuery } from "src/store/api";
import { filterSelector } from "src/store/slices/filterSlice";
import { useStoreSelector } from "src/store/store";
import { pluralize } from "src/utils/pluralize";

const columns = [
  {
    title: "Дата",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "Кол-во гостей",
    dataIndex: "visitorsAmount",
    key: "visitorsAmount",
  },
  {
    title: "Кол-во отказов",
    dataIndex: "rejectsAmount",
    key: "rejectsAmount",
  },
  // Пока не реализовано
  // {
  //   title: "Управление",
  //   key: "action",
  //   render: () => (
  //     <button className={"text-primary-6 font-medium"}>Выгрузить</button>
  //   ),
  // },
];

export const TableWrapper = () => {
  const [paginationParams, setPaginationParams] = useState({
    page: 1,
    pageSize: 10,
  });
  const { period } = useStoreSelector(filterSelector);
  const { currentData, isFetching } = useGetListStatisticDataQuery({
    period: period,
    page: paginationParams.page,
  });

  const tableData = currentData?.items.map((item, i) => {
    return {
      key: i,
      date:
        period === "day"
          ? new Date(item.date).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            })
          : new Date(item.date).toLocaleDateString(),
      visitorsAmount: `${item.visitorsAmount} ${pluralize(item.visitorsAmount, [
        "гостей",
        "гостя",
        "гость",
      ])}`,
      rejectsAmount: `${item.rejectsAmount} ${pluralize(item.rejectsAmount, [
        "отказов",
        "отказа",
        "отказ",
      ])}`,
    };
  });

  return (
    <div className="pt-4">
      <Table
        dataSource={tableData}
        columns={columns}
        pagination={false}
        loading={{
          indicator: (
            <div className={"flex items-center justify-center h-full"}>
              {" "}
              <Spin />
            </div>
          ),
          spinning: isFetching,
        }}
      />

      <Pagination
        className={"text-right py-4"}
        onChange={(page, pageSize) => {
          setPaginationParams({ page, pageSize });
        }}
        defaultCurrent={1}
        total={500}
      />
    </div>
  );
};
