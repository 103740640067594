import { Select } from 'antd';
import { filterSelector } from 'src/store/slices/filterSlice';
import { useStoreSelector } from 'src/store/store';

const options = [
  { value: 'day', label: 'За день' },
  { value: 'week', label: 'За неделю' },
  { value: 'month', label: 'За месяц' },
  { value: 'year', label: 'За год' },
]

export const SelectWrapper = ({ onChange }: { onChange: (value: string) => void }) => {
  const { period } = useStoreSelector(filterSelector);

  return (
    <Select className='min-w-[100px]'
      options={options}
      defaultValue={period}
      onChange={onChange}
    />)
}
