import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { api } from './api';
import { authSlice } from './slices/authSlice';
import { filterSlice } from './slices/filterSlice';

export const store = configureStore({
  reducer: {
    api: api.reducer,
    auth: authSlice.reducer,
    filter: filterSlice.reducer,
  },
  devTools: true,
  middleware(getDefaultMiddleware) {
    return getDefaultMiddleware().concat(api.middleware);
  },
});

export type StoreRootState = ReturnType<typeof store.getState>;
export type StoreDispatch = typeof store.dispatch;

export const useStoreSelector: TypedUseSelectorHook<StoreRootState> = useSelector;
export const useStoreDispatch: () => StoreDispatch = useDispatch;
