import { DashboardLayout } from "../../components/DashboardLayout/DashboardLayout"
import { StatisticList } from "../../components/StatisticList/StatisticList"
import { TableWrapper } from "../../components/TableWrapper/TableWrapper"

export const ListPage = () => {
  return <DashboardLayout>
    <>
      <StatisticList />
      <TableWrapper />
    </>
  </DashboardLayout>
}