import React from "react";
import { Area } from "@ant-design/charts";
import { DateType } from "src/pages/Graphics/GraphicsPage";
import { Spin } from "antd";

type PlotAreaProps = {
  colorPalette?: string[];
  data: DateType[];
  isFetching: boolean;
};

export const PlotArea: React.FC<PlotAreaProps> = ({
  colorPalette,
  data,
  isFetching,
}) => {
  const scaleColor = colorPalette ? { range: colorPalette ?? [] } : {};

  const config = {
    data,
    xField: "unit",
    yField: "value",
    colorField: "type",
    tooltip: {
      title: "",
      items: [
        {
          channel: "y",
          valueFormatter: (d: number) => d.toFixed(2),
        },
      ],
    },
    axis: {
      y: { labelFormatter: "~s" },
    },
    legend: {
      color: {
        itemMarker: 'circle',
        layout: {
          justifyContent: "center",
          alignItems: "flex-end",
        },
      },
    },
    style: {
      opacity: 0.3,
    },
    line: {
      style: {
        strokeWidth: 2,
      },
    },
    scale: {
      color: scaleColor,
    },
  };

  return isFetching ? (
    <div className={"flex items-center justify-center h-[480px]"}>
      <Spin />
    </div>
  ) : (
    <Area {...config} />
  );
};
