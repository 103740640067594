import React from "react";
import { Line } from "@ant-design/charts";
import { DateType } from "src/pages/Graphics/GraphicsPage";
import { Spin } from "antd";

type PlotType = {
  data: DateType[];
  isFetching?: boolean;
};

export const Plot: React.FC<PlotType> = ({ data, isFetching }) => {
  const config = {
    data,
    xField: "unit",
    yField: "value",
    colorField: "type",
    tooltip: {
      title: "",
      items: [{ channel: "y" }],
    },
    legend: {
      color: {
        layout: {
          justifyContent: "center",
          alignItems: "flex-end",
        },
      },
    },
    axis: {
      y: {
        labelFormatter: "~s",
      },
    },
    point: {
      shapeField: "point",
      sizeField: 4,
    },
    style: {
      lineWidth: 2,
    },
  };

  return isFetching ? (
    <div className={"flex items-center justify-center h-[480px]"}>
      <Spin />
    </div>
  ) : (
    <Line {...config} />
  );
};
