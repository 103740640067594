import { GetCommonStatisticResponseDto } from "@generated";


export const data: GetCommonStatisticResponseDto = {
  visitorsAmount: {
    now: 300,
    previous: 290
  },
  averageVisitorsAmount: {
    now: 300,
    previous: 290
  },
  residenceTime: {
    now: 3000,
    previous: 2500
  }
}