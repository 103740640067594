/**
 * Функция склоняет слова, в зависимости от переданного числа
 * @param value - число
 * @param Array - массив слов в порядке [пять, два, один]
 * @example pluralize(3, [год, года, лет]
 */
export function pluralize(value: number, [value1, value2, value3]: [string, string, string]) {
	const preLastDigit = Math.round((value % 100) / 10);

	if (preLastDigit === 1) {
		return value1;
	}

	switch (value % 10) {
		case 1:
			return value3;
		case 2:
		case 3:
		case 4:
			return value2;
		default:
			return value1;
	}
}