import { RadioSwitcher } from '../RadioSwitcher/RadioSwitcher'
import typo from '../UI/Typography/Typography.module.css'
import { SelectWrapper } from '../SelectWrapper/Select';
import { useStoreDispatch } from 'src/store/store';
import { filterSlice } from 'src/store/slices/filterSlice';

export const Navigation = () => {
  const dispatch = useStoreDispatch();

  const handleSelectChange = (value: string) => {
    dispatch(filterSlice.actions.setFilter(value))
  };

  return (
    <nav className={'flex justify-between py-4'}>
      <h2 className={typo.h5 + ' text-character-title'}>Данные посещения ресторанов</h2>

      <div className={'flex gap-2'}>
        <RadioSwitcher />
        {/* <RangePicker className={'ml-2'} /> */}
        <SelectWrapper onChange={handleSelectChange} />
      </div>
    </nav>
  )
}