import { Radio } from 'antd'
import type { RadioChangeEvent } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';

const options = [
  {
    name: 'Список',
    value: 'list'
  },
  {
    name: 'График',
    value: 'graphics'
  }
];

export const RadioSwitcher = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate();

  const defaultValue = options.find(option => {
    return pathname.replace('/', '') === option.value
  })

  const onChange = ({ target: { value } }: RadioChangeEvent) => {
    navigate(`/${value}`)
  };

  return (
    <Radio.Group name="radiogroup" defaultValue={defaultValue?.value} onChange={onChange}>
      {options.map(item => {
        return <Radio.Button key={item.value} value={item.value}>{item.name}</Radio.Button>
      })}
    </Radio.Group>
  )
}