import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RuntimeEnv } from "../modules/runtime-env/RuntimeEnv";
import {
  GetCommonStatisticResponseDto,
  ListStatisticResponseDto,
  GetGraphRejectsStatisticResponseDto,
  GetGraphTimeOfVisitStatisticResponseDto,
  GetGraphVisitorsStatisticResponseDto,
} from "@generated";

type UserData = {
  login: string;
  ro_cameras: string[];
  rw_cameras: string[];
  own_cameras: string[];
  ro_buckets: string[];
  rw_buckets: string[];
};

type ListStatisticRequestDto = {
  period: string;
  page: number;
  amountOtItems?: number;
};

export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: RuntimeEnv.getEnv("REACT_APP_API_URL"),
  }),

  tagTypes: ["User"],
  endpoints(build) {
    return {
      getConfig: build.query<unknown, void>({
        query: () => {
          return "api/config";
        },
      }),
      login: build.mutation({
        query: () => {
          return {
            url: "api/config",
            method: "GET",
          };
        },
      }),
      getUserData: build.query<UserData, string>({
        query: (username) => {
          return {
            url: `/api/user/${username}`,
            method: "GET",
          };
        },
        providesTags: ["User"],
      }),
      getStatisticData: build.query<GetCommonStatisticResponseDto, string>({
        query: (period) => {
          return {
            url: "/getCommonStatistic",
            method: "GET",
            params: {
              period: period,
            },
          };
        },
      }),
      getGraphVisitorsStatisticData: build.query<
        GetGraphVisitorsStatisticResponseDto,
        string
      >({
        query: (period) => {
          return {
            url: "/getGraphVisitorsStatistic",
            method: "GET",
            params: {
              period: period,
            },
          };
        },
      }),
      getGraphRejectsStatistic: build.query<
        GetGraphRejectsStatisticResponseDto,
        string
      >({
        query: (period) => {
          return {
            url: "/getGraphRejectsStatistic",
            method: "GET",
            params: {
              period: period,
            },
          };
        },
      }),
      getGraphTimeOfVisitStatistic: build.query<
        GetGraphTimeOfVisitStatisticResponseDto,
        string
      >({
        query: (period) => {
          return {
            url: "/getGraphTimeOfVisitStatistic",
            method: "GET",
            params: {
              period: period,
            },
          };
        },
      }),
      getListStatisticData: build.query<
        ListStatisticResponseDto,
        ListStatisticRequestDto
      >({
        query: ({ period, page, amountOtItems }) => {
          return {
            url: "/listStatistic",
            method: "GET",
            params: { period, page, amountOtItems },
          };
        },
      }),
    };
  },
});

export const {
  useGetConfigQuery,
  useLoginMutation,
  useLazyGetUserDataQuery,
  useGetUserDataQuery,
  useGetStatisticDataQuery,
  useGetGraphVisitorsStatisticDataQuery,
  useGetGraphRejectsStatisticQuery,
  useGetGraphTimeOfVisitStatisticQuery,
  useGetListStatisticDataQuery,
} = api;
