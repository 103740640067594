import { Button } from "antd";
import { useNavigate } from "react-router-dom";

export const NotFoundPage = () => {
  const navigate = useNavigate();
  const onBackClick = () => {
    navigate('/');
  };

  return (
    <div className="flex w-[100vw] h-[100vh] justify-center items-center p-6">
      <div className={'flex flex-col text-center gap-4'}>
        <h1 className={'text-[40px]'}>Что-то пошло не так....</h1>
        <p className={'opacity-70 text-[20px]'}>Попробуйте перезагрузить страницу или вернуться в главную меню</p>
        <Button onClick={onBackClick}>
          На главную
        </Button>
      </div>
    </div>
  )
}