import { StatisticWrapper } from "../StatisticWrapper/StatisticWrapper";
import { useGetStatisticDataQuery } from "../../store/api";
import { useStoreSelector } from "src/store/store";
import { filterSelector } from "src/store/slices/filterSlice";
import { pluralize } from "src/utils/pluralize";
import { data } from "src/mock/StatisticMock";

export const StatisticList = () => {
  const { period } = useStoreSelector(filterSelector);
  const { currentData, isFetching } = useGetStatisticDataQuery(period);

  return (
    <div className="px-6 py-4 rounded-2 bg-gray-2 border border-divider flex gap-8">
      <StatisticWrapper
        data={{
          now: currentData?.visitorsAmount.now || 0,
          previous: currentData?.visitorsAmount.previous || 0,
        }}
        isFetching={isFetching}
        title={"Посетителей"}
        suffix={pluralize(data.visitorsAmount.now, [
          "человек",
          "человека",
          "человек",
        ])}
      />

      <StatisticWrapper
        data={{
          now: currentData?.averageVisitorsAmount.now || 0,
          previous: currentData?.averageVisitorsAmount.previous || 0,
        }}
        title={"В среднем посетителей"}
        isFetching={isFetching}
        suffix={`${pluralize(currentData?.averageVisitorsAmount.now || 0, [
          "посетителей",
          "посетителя",
          "посетитель",
        ])} в день`}
      />
      <StatisticWrapper
        data={{
          now: currentData ? Math.floor(currentData.residenceTime.now / 60) : 0,
          previous: currentData
            ? Math.floor(currentData.residenceTime.now / 60)
            : 0,
        }}
        title={"Время нахождения "}
        isFetching={isFetching}
        suffix={pluralize(
          currentData ? Math.floor(currentData.residenceTime.now / 60) : 0,
          ["минут", "минуты", "минута"]
        )}
      />
    </div>
  );
};
